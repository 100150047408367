<template>
    <!--static-->
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @shown="initData()"
            @hide="resetForm()"
    >

        <template #modal-title>
            {{$t('label_pattern_tags')}}
        </template>
        <template #default="{ hide }">
            <b-row class="mt-2 mb-2">
                <b-col cols="12" md="6" class="mb-2 ">
                    <label>{{$t('label_pattern_tags_types')}}</label>
                    <v-select
                            :disabled="disabled"
                            v-model="filterData.short_code_type"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="shortCodeTypes"
                            :reduce="val => val.id"
                            label="idCountry"
                            :clearable="false"
                            :placeholder="$t('label_select')"
                    >
                        <template v-slot:option="option">
                            {{$t(option.lang_id)}}
                        </template>
                        <template v-slot:selected-option="option">
                            {{$t(option.lang_id)}}
                        </template>
                        <template v-slot:no-options="option">
                            {{$t('label_no_items')}}
                        </template>
                    </v-select>
                </b-col>
                <b-col cols="12" md="6">
                    <label>{{$t('label_pattern_tags_name')}}</label>
                    <b-form-input
                            v-model="tableData.searchQuery"
                            :placeholder="$t('label_pattern_tags_name')"

                            trim
                    />


                </b-col>
            </b-row>
            <!--<vue-perfect-scrollbar-->
            <!--style="height:300px"-->
            <!--:settings="perfectScrollbarSettings"-->
            <!--class="scroll-area"-->
            <!--&gt;-->
            <div class="table-container-wrap">
                <b-table

                        :ref="PREFIX+'_TABLE'"
                        class="position-relative transited-table"
                        :items="tableItems"
                        responsive
                        sticky-header="350px"
                        no-border-collapse
                        :fields="columnsDefs"
                        primary-key="id"
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"
                >

                    <template #head()="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                    </template>

                    <template #cell(language_type_id)="data">

                        {{$t(data.item.parentName)+': '+$t(data.item.language_type_id)}}

                    </template>


                    <template #cell(actions)="data">

                        <b-button size="sm" variant="outline-primary"
                                  @click="doCopy(data.item.short_code)"
                        >
                            <feather-icon icon="CopyIcon"></feather-icon>
                        </b-button>
                    </template>

                </b-table>
            </div>

            <!--</vue-perfect-scrollbar>-->
        </template>

    </b-modal>
</template>


<script>

    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import {
        BButton,
        BTable,
        BFormInput,
    } from 'bootstrap-vue'

    import {MODULE_PREFIX, SHORTCODE_PREFIX as PREFIX} from './../moduleHelper'
    import vSelect from 'vue-select'

    export default {
        components: {
            BButton,
            BTable,
            BFormInput,
            VuePerfectScrollbar,
            vSelect
        },
        props: ['editedItem', 'type', 'disabled'],
        data() {
            return {
                MODULE_PREFIX,
                PREFIX,

                columnsDefs: [
                    {label: '#', key: 'id', thClass: 'hidden', tdClass: 'hidden'},
                    {label: 'label_pattern_tags_name', key: 'language_type_id', sortable: false},
                    {label: 'label_copy', key: 'actions'}
                ],

                filterData: {
                    short_code_type: ''
                },

                shortCodeTypes: [],

                originalData: [],

                tableItems: [],
                tableTotal: 0,
                isBusy: false,
                tableData: {
                    currentPage: 1,
                    perPage: 1000,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    // perPageOptions: [ 25, 50, 100],
                },

            }
        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function () {
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    }, 100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },
        methods: {
            initData() {
                this.isBusy = true;
                if(this.originalData.length < 1) {
                    this.async('get', this.MODULE_PREFIX + '_' + PREFIX + '_forms', {
                        params: {
                            length: this.tableData.perPage,
                            start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                            search: this.tableData.searchQuery,
                            sort_by: this.tableData.sortBy,
                            sort_desc: this.tableData.sortDesc ? true : null,

                        }
                    }, function (resp) {

                        let items = [];
                        for (let i = 0; i < resp.data.length; i++) {
                            this.shortCodeTypes.push(resp.data[i]);
                            for (let j = 0; j < resp.data[i].short_codes.length; j++) {
                                if (resp.data[i].short_codes[j].status == 'Y')
                                    items.push(Object.assign({parentName: resp.data[i].lang_id}, resp.data[i].short_codes[j]));
                            }
                        }

                        if(this.type){

                            if(this.type == 'meeting-mail'){
                                items = items.filter((item) => {
                                    return item.form_id == 8 || item.form_id == 1;
                                });
                                this.shortCodeTypes = this.shortCodeTypes.filter((item) => {
                                    return item.id == 8 || item.id == 1;
                                });
                                this.filterData.short_code_type = '8';
                            } else if (this.type == 'responsible-user-mail') {
                                items = items.filter((item) => {
                                    return item.form_id == 9;
                                });
                                this.shortCodeTypes = this.shortCodeTypes.filter((item) => {
                                    return item.id == 9;
                                });
                                this.filterData.short_code_type = '9';
                            } else if (this.type == 'mail-footer' || this.type == 'mail-user-settings') {
                                items = items.filter((item) => {
                                    return ["{code.user.phone}","{code.user.emailAddress}", "{code.user.fullName}"].includes(item.short_code);
                                });
                                this.shortCodeTypes = this.shortCodeTypes.filter((item) => {
                                    return item.id == 4;
                                });
                                this.filterData.short_code_type = '4';
                            } else if (this.type == 'agreement-deadline') {
                                items = items.filter((item) => {
                                    return item.form_id == 2;
                                });
                                this.shortCodeTypes = this.shortCodeTypes.filter((item) => {
                                    return item.id == 2;
                                });
                                this.filterData.short_code_type = '2';
                            }


                        }

                        this.originalData = items;
// console.log('this.originalData',this.originalData);
// console.log('this.shortCodeTypes',this.shortCodeTypes);
                        this.tableItems = items;
                        this.tableTotal = resp.data.length;

                        this.isBusy = false;
                        // if(this.type){
                        //     this.filterData.short_code_type = parseInt(this.type);
                        // }
                    });
                }


            },
            resetForm() {

            },
            refreshDataTable() {
                this.isBusy = true;
                let items = this.originalData;

                if (this.filterData.short_code_type ) {

                    items = items.filter((item) => {
                        return item.form_id == this.filterData.short_code_type;
                    });
                }

                if (this.tableData.searchQuery) {
                    items = items.filter((item) => {
                        return item.short_code.includes(this.tableData.searchQuery);
                    });
                }

                this.tableItems = items;
                this.isBusy = false;
            },

            doCopy(text) {

                let self = this;
                this.$copyText(text, this.$refs[this.PREFIX + '_TABLE'].$el).then(() => {
                    self.$bvModal.hide(this.PREFIX + '-modal');
                    self.showToast('info', this.$t('text_copied'));
                }, () => {
                    self.$bvModal.hide(this.PREFIX + '-modal');
                })
            },

        },


    }
</script>